/* tslint:disable */
import Helmet from 'react-helmet';
import React from 'react';

export interface METAProps {
  name?: string;
  content?: string | boolean;
  property?: undefined;
}

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: METAProps[];
  keywords?: any;
  title: string;
  author?: string;
};

const SEO: React.FC<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  author
}) => {
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={'%s | Melhor coffee メルローコーヒー'}
      meta={
        [
          {
            name: 'description',
            content: metaDescription
          },
          {
            name: 'google-site-verification',
            content: ''
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:description',
            content: metaDescription
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'twitter:card',
            content: 'summary'
          },
          {
            name: 'twitter:creator',
            content: author
          },
          {
            name: 'twitter:title',
            content: title
          },
          {
            name: 'twitter:description',
            content: metaDescription
          },
          {
            name: 'al:web:should_fallback',
            content: ''
          }
        ]
          .concat(
            keywords.length > 0
              ? {
                name: 'keywords',
                content: keywords,
              }
              : [],
          )
        // .concat(meta)
      }
      link={[
        { rel: 'canonical', href: '/' },
        { rel: 'apple-touch-icon', href: '/img/apple-touch-icon-180x180.png' },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: '/img/apple-touch-icon-72x72.png'
        }
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'ja',
  meta: [],
  keywords: [],
  description: 'お届けするコーヒーは常に焙煎したてのフレッシュなもの。コーヒーは焙煎後２～３日で味わい・香りが最高潮に達するといわれています。焙煎して、お客様のもとに届く時が、まさに最高のタイミングです。'
};

export default SEO;
