require('intersection-observer');
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Reveal, Animation } from 'react-genie';
import { isMobile, isIOS } from 'react-device-detect';

import { IndexPageTemplateQuery } from 'types/graphql-types';
import Layout from '../components/Layout';
import SEO from '../components/seo';

// import bgMovie from './../movie/coffee_cup.mp4';

import {
  IoLogoFacebook,
  IoLogoInstagram,
} from 'react-icons/io';

import {
  FaLine
} from 'react-icons/fa';

import slide_001 from './../img/top/slide_001.jpg';
import slide_002 from './../img/top/slide_002.jpg';
import slide_003 from './../img/top/slide_003.jpg';
import slide_004 from './../img/top/slide_004.jpg';

import image001 from './../img/top/image_001.jpg';
import image002 from './../img/top/image_002.jpg';
import image003 from './../img/top/image_003.jpg';
import fullBanner from './../img/top/full_banner.jpg';
import fullDripBagBanner from './../img/top/full_dripbag_banner.jpg';

import H2Title from '../components/H2Title';


import { Wrapper, Body, Section, Contents, FullSectionContents } from '../theme/global';
import TopAboutSection from '../components/TopAboutSection';
import FullCaptureSection from '../components/FullCaptureSection';
import TopMediaSection from '../components/TopMediaSection';
import SocialSection from '../components/SocialSection';
import SmoothScrolling from '../services/anchorLink';
import TopSlider from '../components/TopSlider';

const facebookID = '111644983552550';
const androidFacebookURL = `fb://page/${facebookID}`;
const iOSFacebookURL = `fb://profile/${facebookID}`;
const webFacebookURL = 'https://www.facebook.com/melhorcoffee/';

type IndexPageTemplateProps = RecursiveNonNullable<
  IndexPageTemplateQuery
>['markdownRemark']['frontmatter'];

const scrollToSnsSection = () => {
  SmoothScrolling.scrollTo('sns_section', null);
  // window.location.pathname === "/"
  //   ? SmoothScrolling.scrollTo("medical_section", null)
  //   : navigate(`/#medical-section`);
};

const slideImage = [
  {
    path: slide_001,
    alt: 'coffee'
  },
  {
    path: slide_002,
    alt: 'coffee'
  },
  {
    path: slide_003,
    alt: 'coffee'
  },
  {
    path: slide_004,
    alt: 'coffee'
  }
];

export const IndexPageTemplate = () => (
  <Wrapper>
    <Section>
      <TopSlider slideImages={slideImage} title="Melhor coffee" description="since 2015" />

      <Body>
        <Reveal delay={300} animation={Animation.FadeInUp}>
          <Section>
            <H2Title title="About Melhor Coffee" description="Melhor Coffeeとは？" borderColor="red" />

            <Reveal delay={300} animation={Animation.FadeInUp}>
              <Contents>
                <TopAboutSection
                  mt={[9, 14]}
                  topTitle={'Melhor'}
                  adjustTitle={'='}
                  bottomTitle={'“ 最高の ”'}
                  description={'“ Melhorはポルトガル語で「最高、ベスト」という意味 “'}
                  text=""
                />
                {/* <FullCaptureSection mt={[9, 14]} image={fullBanner} /> */}
              </Contents>
            </Reveal>
          </Section>
        </Reveal>
        <Reveal delay={300} animation={Animation.FadeInUp}>
          <Section mt={[9, 14]}>
            <H2Title title="Choose Coffee Beans" description="MelhorなCoffeeを届けるために" borderColor="red" />

            <Contents>
              <Reveal delay={300} animation={Animation.FadeInUp}>

                <TopMediaSection
                  mt={[7, 14]}
                  number="01"
                  title="コーヒー豆の選択"
                  description="コーヒー豆の品質だけでなく、コーヒーを栽培してる農園の生産体制、栽培の状況などを視察し常に最高のコーヒーを選んでいます。"
                  tags={
                    [
                      {
                        text: '#珈琲の品質'
                      },
                      {
                        text: '#生産体制の管理'
                      }
                    ]
                  }
                  image={image001}
                  alt="コーヒー豆の選択"
                />

              </Reveal>
              <Reveal delay={300} animation={Animation.FadeInUp}>
                <TopMediaSection
                  mt={[9, 14]}
                  number="02"
                  title="コーヒー豆の選別"
                  description="コーヒー豆には死豆など、コーヒーの味を害するものがあります。その豆を人の手でチェックし、最高の状態の豆の味を使用しています。"
                  tags={
                    [
                      {
                        text: '#ひとつひとつ人の手で'
                      }
                    ]
                  }
                  image={image002}
                  alt="コーヒー豆の選別"
                />
              </Reveal>
              <Reveal delay={300} animation={Animation.FadeInUp}>
                <TopMediaSection
                  mt={[9, 14]}
                  number="03"
                  title="コーヒー豆の焙煎"
                  description="お届けするコーヒーは常に焙煎したてのフレッシュなもの。コーヒーは焙煎後２～３日で味わい・香りが最高潮に達するといわれています。焙煎して、お客様のもとに届く時が、まさに最高のタイミングです。"
                  tags={
                    [
                      {
                        text: '#豆の鮮度'
                      },
                      {
                        text: '#最高のタイミングでお届け'
                      }
                    ]
                  }
                  image={image003}
                  alt="コーヒー豆の焙煎"
                />
              </Reveal>
            </Contents>
          </Section>
        </Reveal>
        <Reveal delay={300} animation={Animation.FadeInUp}>
          <Section mt={[9, 14]}>
            <H2Title title="About Shop" description="販売について" borderColor="red" />

            <Reveal delay={300} animation={Animation.FadeInUp}>
              <Contents mt={[7, 8]}>
                <p className="c-text">コロナの影響の為、現在 <span className="c-double-text -red">ドリップバック</span> は予約販売のみしております。</p>
                <p className="c-text u-mt10">コーヒー豆のサブスクリプション（毎月の定期お届け） は引き続き受け付けております。</p>
                {/* <p className="c-text">販売日は <span className="c-double-text -red">月に２回</span> 、不定期で行います。（豆の仕入れ状況によるため）</p> */}

                {/* <p className="c-annotation u-pc-mt10 u-sp-mt30">販売開始はSNSでお知らせ致します。 <span onClick={scrollToSnsSection} className="c-link">SNSはこちら</span></p> */}
                {/* <p className="c-annotation u-pc-mt10 u-sp-mt30">その月の販売日はSNSを通じでお知らせします。<a href="#" className="c-link" target="_blank">SNSはこちら</a></p> */}

                <div className="c-full-banner u-pc-mt20 u-sp-mt10">
                  <FullCaptureSection radius image={fullDripBagBanner} alt="ドリップバッグセット" url="https://melhor-coffee.shop/items/5e901222e20b04530d31faa3" />
                </div>

                <div className="c-button -border-button u-dis-b u-pc-mt40 u-sp-mt20">
                  <div className="c-button__inner">
                    <a
                      className="c-button__text u-sp-w100p"
                      href="https://melhor-coffee.shop/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      オンラインストアへ
                      </a>
                  </div>
                </div>
              </Contents>
            </Reveal>
          </Section>
        </Reveal>
      </Body>
    </Section>

    <Reveal delay={300} animation={Animation.FadeInUp}>
      <SocialSection socials={[
        {
          icon: <FaLine />,
          url: 'https://lin.ee/1LH1W9Y',
          tooltip: 'LINE',
        },
        {
          icon: <IoLogoFacebook />,
          url: isMobile
            ? isIOS
              ? iOSFacebookURL
              : androidFacebookURL
            : webFacebookURL,

          tooltip: 'Facebook',
        },
        {
          icon: <IoLogoInstagram />,
          url: 'https://www.instagram.com/melhor_coffee/',
          tooltip: 'Instagram',
        },
      ]} title="Melhor Coffee Officail SNS" description="販売日など最新の情報をお知らせします" mt={[9, 14]} />
    </Reveal>
    <Reveal delay={300} animation={Animation.FadeInUp}>
      <FullSectionContents mt={[9, 14]}>
        <FullCaptureSection small radius image={fullBanner} alt="ONLINE STORE" url="https://melhor-coffee.shop/" />
      </FullSectionContents>
    </Reveal>
  </Wrapper >
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({
  data
}: {
  data: RecursiveNonNullable<IndexPageTemplateQuery>;
}) => {
  const { markdownRemark: post } = data;
  const [] = useState(false);
  return (
    <Layout>
      <SEO
        title="農園から一杯のカップまで"
        description="お届けするコーヒーは常に焙煎したてのフレッシュなもの。コーヒーは焙煎後２～３日で味わい・香りが最高潮に達するといわれています。焙煎して、お客様のもとに届く時が、まさに最高のタイミングです。"
        keywords="Melhor Coffee,メルローコーヒー,コーヒー,コーヒー豆,通販,リモートワーク,在宅ワーク"
      />
      {/* <button onClick={() => setIsOpen(!isOpen)}>toggle</button>
      <Popup isOpen={isOpen} >Lorem ipsum</Popup> */}
      <IndexPageTemplate
        image={post ?.frontmatter ?.image}
        title={post ?.frontmatter ?.title}
        heading={post ?.frontmatter ?.heading}
        subheading={post ?.frontmatter ?.subheading}
        mainpitch={post ?.frontmatter ?.mainpitch}
        description={post ?.frontmatter ?.description}
        intro={post ?.frontmatter ?.intro}
      />

    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
