import { IoIosArrowUp } from 'react-icons/io';
import React from 'react';
import Slider from 'react-slick';
import { Wrapper, Contents, Title, Description, Tooltip } from './style';
import { Reveal, Animation, RevealMode } from 'react-genie';
import { v4 } from 'uuid';

type SlideImage = {
  path: string;
  alt: string;
}

type TopSliderProps = {
  slideImages: SlideImage[];
  title?: string;
  description?: string;
};

const settings = {
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 4000,
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const TopSlider: React.FC<TopSliderProps> = ({ slideImages, title, description }) => {
  return (
    <Wrapper>
      <Reveal delay={900} mode={RevealMode.Clone} animation={Animation.FadeInUp}>
        <Contents>
          <Title fontSize={[7, 9]}>{title}</Title>
          <Description fontSize={[3, 5]}>{description}</Description>
          <div className="c-button u-pc-mt20 u-sp-mt15">
            <div className="c-button__inner">
              <a
                className="c-button__text u-sp-w100p"
                href="https://melhor-coffee.shop/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Official shop */}
                ONLINE STORE
              </a>
              <Tooltip>\コンニチワ/</Tooltip>
            </div>
          </div>
        </Contents>
      </Reveal>
      <Slider {...settings} className="c-top-slide">
        {
          slideImages.map((item: SlideImage) => {
            return (
              <div key={v4()} className="c-top-slide__image-outer">
                {/* <img src={item.path} alt={item.alt} style={{ width: '100%' }} /> */}
                <div className="c-top-slide__image" style={{ backgroundImage: `url(${item.path})` }}>
                  <div className="c-top-slide__image__inner"></div>
                </div>
              </div>
            );
          })
        }
      </Slider>
    </Wrapper>
  );
};

export default TopSlider;
