import React from 'react';
import { v4 } from 'uuid';
import * as C from './style';

type SocialProps = {
  icon: React.ReactChild;
  url: string;
  tooltip: string;
};

type SocialSectionProps = {
  title: string;
  description: string;
  mt?: {};
  socials: SocialProps[];
};

const SocialSection: React.FC<SocialSectionProps> = ({ mt, title, description, socials }) => {
  return (
    <C.Wrapper mt={mt} id="sns_section">
      <C.Inner>
        <C.Contents>
          <C.Title fontSize={[5, 6]}>{title}</C.Title>
          <C.Description fontSize={[3, 4]} mt={[2, 3]}>{description}</C.Description>
        </C.Contents>
        <C.Socials mt={[6, 8]}>
          {
            socials.map((item) => {
              return (
                <C.SocialsItem key={v4()} className={`${item.tooltip === 'LINE' && 'line'}`}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer" className={`${item.tooltip === 'LINE' && 'line'}`}>
                    {item.icon || 'icon'}
                  </a>
                  <C.Tooltip>{item.tooltip || 'Social Link'}</C.Tooltip>
                </C.SocialsItem>
              );
            })
          }
        </C.Socials>
      </C.Inner>
    </C.Wrapper>
  );
};

export default SocialSection;
