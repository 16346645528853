import styled from 'styled-components';
import { fontSize, space } from 'styled-system';
import mq from './../../theme/media';
import { theme } from './../../theme';
import { DefaultStyledSystemProps } from './../../types/Common';

export const Wrapper = styled.section<DefaultStyledSystemProps>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};

  ${mq.down`
    display: block;
    width: 100%;
    border-left: none;
  `}
`;

export const LeftCol = styled.div<DefaultStyledSystemProps>`
  width: 70%;
  padding-right: 5%;

  ${mq.down`
    width: 100%;
    padding-right: 0;
  `}
`;

export const RightCol = styled.div<DefaultStyledSystemProps>`
  width: 30%;
  padding: 0 6%;

  ${mq.down`
    width: 100%;
    padding: 0;
  `}
`;

export const BorderTop = styled.div<DefaultStyledSystemProps>`
  width: 20%;
  ${space};
  border-top: 2px solid ${theme.colors.black};
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  padding: 5px 0;
`;

export const TitleWrap = styled.h3<DefaultStyledSystemProps>`
  display: flex;
  ${space};
  align-items: center;
`;

export const TopTitle = styled.span<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.black};

  ${mq.se`
    font-size: 24px;
  `}
`;

export const AdjustTitle = styled.span<DefaultStyledSystemProps>`
  ${fontSize};
  font-weight: bold;
  color: ${theme.colors.black};
  width: 20%;
  text-align: center;

  ${mq.se`
    font-size: 24px;
  `}
`;

export const BottomTitle = styled.span<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.black};

  ${mq.se`
    font-size: 24px;
  `}
`;

export const Description = styled.p<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.gray};
  font-weight: bold;
`;

export const Text = styled.p<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.black};
  font-weight: bold;
`;

export const Logo = styled.div<DefaultStyledSystemProps>`

  ${mq.down`
    width: 30%;
    margin: 50px auto 0;
  `}

  img {
    display: block;
  }
`;
