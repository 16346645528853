import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { fontSize, space } from 'styled-system';
import mq from './../../theme/media';
import { theme } from './../../theme';
import { DefaultStyledSystemProps } from './../../types/Common';

export const Tooltip = styled.div`
  position: absolute;
  background-color: #fff;
  pointer-events: none;
  padding: 0.4rem 0.8rem;
  border-radius: 3px;
  color: #333;
  font-size: 10px;
  bottom: 90%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, 0, 0);
  transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Wrapper = styled.div<DefaultStyledSystemProps>`
  position: relative;
  background-position: 50%;
  background-size: cover;
  /* margin-bottom: 12vw;
  padding-top: 56.3%; */
  transition: 0.3s ease-in-out;
  padding-top: 100vh;
  margin-bottom: 5vw;
  overflow: hidden;

  ${mq.down`
    padding-top: 100vh;
    margin-bottom: 20vw;
  `}

  .c-top-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    animation: anime 40s 0s infinite;

    .slick-slide {
      padding-top: 100vh;
      overflow: hidden;

      ${mq.down`
        padding-top: 100vh;
        overflow: hidden;
      `}
    }

    &__image-outer {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__image {
      position: relative;
      background-position: 50%;
      background-size: cover;

      &__inner {
        padding-top: 100vh;

        ${mq.down`
          padding-top: 100vh;
        `}
      }
    }

    img {
      animation: anime 30s 0s infinite;
      /* animation: anime 40s 0s infinite; */
      /* transition: all 10s cubic-bezier(0.7, 0, 0.3, 1);
      transform: scale(1.1); */

      ${mq.down`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      `}
    }

    .slick-active {
      img {
        transform: scale(1);
      }
    }
  }



  .c-button {
    position: relative;
    text-align: center;

    ${mq.down`
      margin: 0 30%;
    `}

    &__inner {
      display: inline-block;
      justify-content: center;

      ${mq.down`
        width: 100%;
      `}

      &:hover {
        opacity: 1;

        .c-button__text {
          opacity: 1;
        }

      ${Tooltip} {
          opacity: 1;
          visibility: visible;
          bottom: calc(100% + 10px);
        }
      }
    }

    &__text {
      padding: 10px 30px;

      ${mq.down`
        padding: 10px 2.5%;
        font-size: 13px;
      `}
    }
  }
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  margin: auto;
  width: auto;
  height: 175px;
  text-align: center;

  ${mq.down`
    bottom: 30px;
    height: 155px;
  `}
`;

export const Title = styled.h1<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.white};
  text-shadow: 1px 0 12px rgba(0,0,0,.2);
`;

export const Description = styled.p<DefaultStyledSystemProps>`
  ${fontSize};
  color: ${theme.colors.white};
  text-shadow: 1px 0 12px rgba(0,0,0,.2);
`;
