import React from 'react';
import { Wrapper, LeftCol, RightCol, Contents, Description, Logo, TitleWrap, TopTitle, AdjustTitle, BottomTitle, Text, BorderTop } from './style';

// images
import LogoImage from './../../img/logo.svg';

type TopAboutSectionProps = {
  mt?: {};
  topTitle: string;
  adjustTitle: string;
  bottomTitle: string;
  description: string;
  text: string;
};

const TopAboutSection: React.FC<TopAboutSectionProps> = ({ mt, topTitle, adjustTitle, bottomTitle, description, text }) => {
  return (
    <Wrapper mt={mt}>
      <LeftCol>
        <Contents>
          <TitleWrap>
            <TopTitle fontSize={[7, 8]}>{topTitle}</TopTitle>
            <AdjustTitle fontSize={[6, 7]}>{adjustTitle}</AdjustTitle>
            <BottomTitle fontSize={[6, 7]}>{bottomTitle}</BottomTitle>
          </TitleWrap>
          <Description fontSize={[1, 1]}>{description}</Description>
          <BorderTop mt={[6, 7]} pt={[6, 7]} />
          <Text>{text}</Text>
        </Contents>
      </LeftCol>
      <RightCol>
        <Logo><img src={LogoImage} alt="Melhor Coffee" /></Logo>
      </RightCol>
    </Wrapper>
  );
};

export default TopAboutSection;
