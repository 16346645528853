import styled from 'styled-components';
import { space } from 'styled-system';
import mq from './media';
import { DefaultStyledSystemProps } from './../types/Common';

export const Wrapper = styled.div<DefaultStyledSystemProps>`
  /* display: flex; */
  /* justify-content: center; */
  margin: 0 0 20px;
`;

export const Body = styled.div<DefaultStyledSystemProps>`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4%;

  ${mq.down`
    max-width: 100%;
    padding: 0 4%;
  `}
`;

export const Section = styled.section<DefaultStyledSystemProps>`
  ${space};
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  ${space};
  padding: 0 4%;

  ${mq.down`
    max-width: 100%;
    padding: 0 4%;
  `}
`;

export const FullSectionContents = styled.div<DefaultStyledSystemProps>`
  ${space};

  ${mq.down`
    max-width: 100%;
    padding: 0 4%;
  `}
`;

