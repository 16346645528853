import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { fontSize, space } from 'styled-system';
import mq from './../../theme/media';
import { theme } from './../../theme';
import { DefaultStyledSystemProps } from './../../types/Common';

export const Tooltip = styled.div`
  position: absolute;
  background-color: #fff;
  pointer-events: none;
  padding: 0.6rem 1.2rem;
  border-radius: 3px;
  color: #333;
  font-size: 14px;
  bottom: 90%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, 0, 0);
  transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Wrapper = styled.div<DefaultStyledSystemProps>`
  position: relative;
  z-index: 1;
  display: block;
  ${space};

  ${mq.down`
    margin: 40px -4% 0;
  `}

  &.-small {
    ${mq.down`
      margin: 40px 0 0;
    `}
  }

  &:hover {
    opacity: 1;

    ${Tooltip} {
      opacity: 1;
      visibility: visible;
      bottom: calc(100% + 10px);
    }
  }
`;

export const Image = styled.a<DefaultStyledSystemProps>`
  display: block;
  /* width: 758px; */
  margin: 0 auto;

  &:hover {
    opacity: 1;
  }

  .l-full-capture-section.-small & {
    max-height: 179px;
    overflow: hidden;
  }

  img {
    display: block;
    margin: 0 auto;

    .l-full-capture-section.-small & {
      max-height: 179px;
      overflow: hidden;
    }

    .l-full-capture-section.-radius & img {
      border-radius: 3px;
    }
  }

  .l-full-capture-section.-radius & img {
    border-radius: 3px;
  }
`;
