import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { fontSize, space } from 'styled-system';
import mq from './../../theme/media';
import { theme } from './../../theme';
import { DefaultStyledSystemProps } from './../../types/Common';

export const Wrapper = styled.section<DefaultStyledSystemProps>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};

  ${mq.down`
    display: block;
    width: 100%;
    border-left: none;
  `}
`;

export const LeftCol = styled.div<DefaultStyledSystemProps>`
  width: 55%;
  padding-right: 5%;

  ${mq.down`
    width: 100%;
    padding-right: 0;
  `}
`;

export const RightCol = styled.div<DefaultStyledSystemProps>`
  width: 45%;
  padding: 0;

  ${mq.down`
    width: 100%;
    padding: 0;
  `}
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  padding: 5px 0;
`;

export const TitleWrap = styled.div<DefaultStyledSystemProps>`
  display: flex;
  flex-direction: column;
  ${space};
`;

export const Title = styled.h3<DefaultStyledSystemProps>`
  ${fontSize};
  ${space};
  font-weight: bold;
  color: ${theme.colors.black};
`;

export const NumberTitle = styled.p<DefaultStyledSystemProps>`
  ${fontSize};
  ${space};
  line-height: 1.2;
  font-weight: bold;
  color: ${theme.colors.lightGray};
  font-family: 'Montserrat';
`;

export const Description = styled.p<DefaultStyledSystemProps>`
  ${fontSize};
  ${space};
  color: ${theme.colors.gray};
`;

export const Picture = styled.div<DefaultStyledSystemProps>`

  ${mq.down`
    margin: 10px auto 0;
    border-radius: 3px;
  `}

  img {
    display: block;

    ${mq.down`
      border-radius: 3px;
    `}
  }
`;

export const TagList = styled.ul<DefaultStyledSystemProps>`
  ${space};
  letter-spacing: -0.4em;
  margin-left: -10px;
  margin-bottom: -10px;
`;

export const TagListItem = styled.li<DefaultStyledSystemProps>`
  display: inline-block;
  letter-spacing: normal;
  margin-left: 10px;
  margin-bottom: 10px;
  color: ${theme.colors.red};
  font-weight: bold;
  ${fontSize};
`;
