import React from 'react';
import * as Custom from './style';
import { v4 } from 'uuid';

type TagProps = {
  text: string;
};

type TopMediaSectionProps = {
  mt?: {};
  number: string;
  title: string;
  description: string;
  tags: TagProps[];
  image: string;
  alt: string;
};


const TopMediaSection: React.FC<TopMediaSectionProps> = ({ mt, number, title, description, tags, image, alt }) => {
  return (
    <Custom.Wrapper mt={mt}>
      <Custom.LeftCol>
        <Custom.Contents>
          <Custom.TitleWrap>
            <Custom.NumberTitle fontSize={[8, 10]}>{number}</Custom.NumberTitle>
            <Custom.Title fontSize={[5, 5]}>{title}</Custom.Title>
          </Custom.TitleWrap>
          <Custom.Description mt={[3, 4]} fontSize={[2, 3]}>{description}</Custom.Description>
          <Custom.TagList mt={[3, 4]}>
            {
              tags.map((item) => {
                return (
                  <Custom.TagListItem key={v4()}>{item.text}</Custom.TagListItem>
                );
              })
            }
          </Custom.TagList>
        </Custom.Contents>
      </Custom.LeftCol>
      <Custom.RightCol>
        <Custom.Picture><img src={image} alt={alt} /></Custom.Picture>
      </Custom.RightCol>
    </Custom.Wrapper>
  );
};

export default TopMediaSection;
