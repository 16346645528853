import { IoIosArrowUp } from 'react-icons/io';
import React from 'react';
import { Wrapper, Contents, Title, Description } from './style';
import { borderColor } from 'styled-system';

type H2TitleProps = {
  mt?: {};
  title: string;
  description: string;
  borderColor?: string;
};

const H2Title: React.FC<H2TitleProps> = ({ mt, title, description, borderColor }) => {
  return (
    <Wrapper mt={mt} className={`c-h2-title ${borderColor === 'red' && '-red'}`}>
      <Contents>
        <Title fontSize={[6, 7]}>{title}</Title>
        <Description fontSize={[4, 5]}>{description}</Description>
      </Contents>
    </Wrapper>
  );
};

export default H2Title;
