import React from 'react';
import { Wrapper, Image, Tooltip } from './style';


type FullCaptureSectionProps = {
  image: string;
  alt: string;
  mt?: {};
  small?: boolean;
  radius?: boolean;
  url?: string;
};


const FullCaptureSection: React.FC<FullCaptureSectionProps> = ({ mt, image, alt, small, radius, url }) => {
  return (
    <Wrapper
      mt={mt}
      className={`l-full-capture-section ${small && '-small'} ${radius && '-radius'} `}
    >
      <Image
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="l-full-capture-section__image"
      >
        <img src={image} alt={alt} />
      </Image>
      <Tooltip>{alt}</Tooltip>
    </Wrapper>
  );
};

export default FullCaptureSection;
