import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { fontSize, space } from 'styled-system';
import mq from '../../theme/media';
import { theme } from '../../theme/index';
import { DefaultStyledSystemProps } from '../../types/Common';

export const Wrapper = styled.div<DefaultStyledSystemProps>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.bgBorder};
  ${space};
`;

export const Inner = styled.div<DefaultStyledSystemProps>`
  display: flex;
  flex-direction: column;
  padding: 100px 6%;
  ${space};

  ${mq.down`
  padding: 50px 4%;
  `}
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  display: flex;
  flex-direction: column;
  ${space};
`;

export const Title = styled.h2<DefaultStyledSystemProps>`
  ${space};
  ${fontSize};
  font-weight: bold;
  text-align: center;
`;

export const Description = styled.p<DefaultStyledSystemProps>`
  ${space};
  ${fontSize};
  font-weight: bold;
  text-align: center;
`;

export const Socials = styled.div<DefaultStyledSystemProps>`
  display: block;
  margin-right: -40px;
  margin-bottom: -10px;
  text-align: center;
  ${space};
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: #fff;
  pointer-events: none;
  padding: 0.4rem 0.8rem;
  border-radius: 3px;
  color: #333;
  font-size: 13px;
  bottom: 90%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, 0, 0);
  transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const SocialsItem = styled.div<DefaultStyledSystemProps>`
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 10px;
  ${space};

  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;
      bottom: calc(100% + 10px);
    }
  }

  &.line {
    margin-right: 44px;
  }


  a {
    display: block;

    &:hover {
      opacity: 1;
    }
  }

  img, svg {
    display: block;
    width: 30px;
    height: auto;
    color: ${theme.colors.black};
  }

  .line {
    img, svg {
      width: 28px;
    }
  }
`;
